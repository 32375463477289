<template>
  <div class="page-login-desktop">

    <Table
        label="تاریخچه اشتراک"
        icon="mdi-format-list-bulleted"
        :table="table"
    >
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.agencyName }}</td>
        <td class="text-center">{{ item.managerMobile }}</td>
        <td class="text-center">{{ item.purchasedate }}</td>
        <td class="text-center">{{ item.subscriptionname }}</td>
        <td class="text-center">{{ item.subscriptiontype }}</td>
      </tr>
    </Table>
  </div>
</template>

<script>
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import Table from "@/components/Table"
import EditButton from "@/components/EditButton"
import MessageButton from "@/components/MessageButton"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {fetchSubscriptionHistory, fetchUserHistory} from "@Newfiling/module-subscription/src/api";
import {addCommas, removeCommas} from "@persian-tools/persian-tools";
import {AppConfig, getDataFromUrl, removeAppConfig, updateUrlQueries} from "@Newfiling/Services";
import Vue from 'vue';

export default {
  name: 'History',

  components: {
    Table,
    Pagination,
    NewMessage,
    datePicker: VuePersianDatetimePicker,
    EditButton,
    MessageButton
  },

  data() {
    return {
      table: {
        headers: [
          '#',
          'آژانس',
          'موبایل مدیر',
          'تاریخ فعال سازی اشتراک',
          'توضیحات',
          'تعداد روز',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },


    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست  مشترکین',
        disabled: false,
        href: '/panel/subscriptions/list',
      },
      {
        text: 'تاریخچه اشتراک',
        disabled: true,
        href: '/panel/subscriptions/list',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      await this.fetchItems();
    })
  },

  methods: {
    async fetchItems() {
      this.table.loading = true;
      try {
        const res = (await fetchSubscriptionHistory(this.$route.params.mobile))?.data
        this.table.totalCount = res.message.length;
        this.table.items = res.message
            .map(item => {
              return {
                agencyName: item.agencyName,
                managerMobile: item.managerMobile,
                purchasedate: item.purchasedate,
                subscriptionname: item.subscriptionname,
                subscriptiontype: item.subscriptiontype,
              }
            });
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

  },
}
</script>
